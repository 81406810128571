// Dependencies
import React, {useState} from 'react';
import { ref as refS, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as refD, set} from 'firebase/database'
import { v4 } from 'uuid';

// Styles
import '../../../App.css';
import './ContactForm.css';
import { FileInputField } from '../../../components/fields/FileInputField';
import { storageDB, realtimeDB } from '../../../database/Firebase';

// Components

// Sections

async function uploadFile(file, uid) {
    const storageRef = refS(storageDB, 'files/' + uid + '/' + file.name);
    const response = uploadBytes(storageRef, file.file);

    const url = await getDownloadURL((await response).ref);

    return url;
}

async function sendMessage(data, files, loadingStatus)
{
    const uid = v4();

    loadingStatus(true);

    const filePromises = Array.from(files, (file) => uploadFile(file, uid));
    const result = await Promise.all(filePromises)

    data.files = result;

    const ref = refD(realtimeDB, "messages/" + uid);

    await set(ref, data)

    loadingStatus(false);
    
    window.location.reload();
}

function ContactForm() {
    const [senderName, setName] = useState('');
    const [senderEmail, setEmail] = useState('');
    const [projectTitle, setTitle] = useState('');
    const [senderMessage, setMessage] = useState('');

    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false)

    const send = (event) => {
        event.preventDefault();

        sendMessage(
            {
                date : new Date().toLocaleString(),
                name : senderName,
                email : senderEmail,
                title : projectTitle,
                message : senderMessage 
            },
            files,
            setLoading
        )
    }

    return (
        <div className='contact-form-container'>
            {
                loading && <div className='upload-overlay'>
                    <div className="loader"></div>
                </div>
            }
            <div className='contact-form-wrapper'>
                <form className='contact-form-form'>
                    <h1 className='contact-form-title' >Send Us a Message</h1>

                    <div className='contact-form-content-wrapper'>
                        <div className='contact-form-content-info'>
                            <input required className='contact-form-info-field' type="text" name="name" placeholder="Your Name" onChange={e => setName(e.target.value)} />
                            <input required className='contact-form-info-field' type="email" name="email" placeholder="Your Email" onChange={e => setEmail(e.target.value)} />
                            <input className='contact-form-info-field' type="text" name="title" placeholder="Project Title" onChange={e => setTitle(e.target.value)} />
                        </div>

                        <div className='contact-form-content-message-wrapper'>
                            <textarea className='contact-form-message-field' type="text" name="message" placeholder="Your Message" onChange={e => setMessage(e.target.value)} />
                        </div>
                    </div>
                    <FileInputField files={files} setFiles={setFiles} />
                    <div className='contact-form-button-wrapper'>
                        <button className='contact-form-submit-button' onClick={send} >Send Message</button>
                    </div>
                </form>
                <div className='contact-form-contact-info'>
                    <h1 data-aos="fade-right" >Contact Info</h1>
                    <div className='contact-form-contact-info-content'>
                        <div data-aos="fade-up" data-aos-delay={300} >
                            <p>Thank you for your interest in ArtisanaVisuals</p>
                            <p>We are here to help!</p>
                            <p>Please feel free to reach out to us using the contact information.</p>
                            <p>We'll respond to your inquiry as soon as possible.</p>
                        </div>
                        <div>
                            <div data-aos="fade-up" data-aos-delay={700} className='contact-form-contact-info-row'>
                                <h3>Address:</h3>
                                <p>Dr. Janka Bulika 98</p>
                            </div>
                            <div data-aos="fade-up" data-aos-delay={1200} className='contact-form-contact-info-row'>
                                <h3>City:</h3>
                                <p>Kovacica</p>
                            </div>
                            <div data-aos="fade-up" data-aos-delay={1700} className='contact-form-contact-info-row'>
                                <h3>Email:</h3>
                                <p>artisanavisuals@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;