// Dependencies
import React from 'react';

// Styles
import 'react-slideshow-image/dist/styles.css';
import '../../../App.css';
import './About.css';

// Components
import { Slideshow } from '../../../components/images/Slideshow';
import { DefaultButton } from '../../../components/buttons/DefaultButton';

// Data
import { slideshow_data_main } from '../../../data/slideshow_data';
import { about_section_data } from '../data/about_section_data';

function About() {
  return (
    <div className='about-container'>
      <div className='images-container'>
        <Slideshow slideshowData={slideshow_data_main} delay={3500}/>
      </div>
      <div className='text-container' data-aos="fade-left">
        {
          about_section_data.map((section_data, index) => {
            return <div key={index} className='text-area'>
              <h1>{section_data.title}</h1>
              <p>{section_data.text}</p>
            </div>
          })
        }
        <DefaultButton animate={true} className='btns' buttonStyle='btn--outline' buttonSize='btn--large' linkTo='/about'>More About Us</DefaultButton>
      </div>
    </div>
  );
}

export default About;