export const navbar_buttons_data = [
    {
        "title" : "Home",
        "type" : "nav-links",
        "path" : "/"
    },
    {
        "title" : "About",
        "type" : "nav-links",
        "path" : "/about"
    },
    {
        "title" : "Services",
        "type" : "nav-links",
        "path" : "/services-list"
    },
    {
        "title" : "Our Projects",
        "type" : "nav-links",
        "path" : "/projects-list"
    },
    {
        "title" : "Contact Us",
        "type" : "nav-links-mobile",
        "path" : "/contact"
    }
]