// Dependencies
import React from 'react'

// Styles
import './Cards.css'

// Components
import CardItem from '../../../components/card_items/CardItem'

// Data
import { services_data } from '../../../data/services_data'

function Cards() {
  const splitArray = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
  );

  return (
    <div className='cards'>
      <h1 data-aos="fade-up" >Take a Quick Look on our Services</h1>
      <div className='cards-separator-container' data-aos="zoom-in">
        <span className='cards-separator' /> <i className='fa fa-circle white' /> <span className='cards-separator' />
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          {
            splitArray(Object.values(services_data), 3).map(
              (services_group, index_1) => {
                return <ul key={index_1} className='cards__items'>
                  {
                    services_group.map(
                      (service, index_2) => {
                        return <CardItem key={index_1 + '-' + index_2} src={service.card} delay={index_2*300} service={service} />
                      }
                    )
                  }
                </ul>
              }
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Cards
