// Dependencies
import React, {useState, useEffect} from 'react'

// Styles
import './CompImg.css';

export const CompareImage = ({
    project
}) => {
    const [position, setPosition] = useState(50);

    const [before_image, setBeforeImage] = useState(project.before_placeholder);
    const [after_image, setAfterImage] = useState(project.after_placeholder);

    const [loadingBefore, setLoadingBefore] = useState(true);
    const [loadingAfter, setLoadingAfter] = useState(true);

    const fetchImage = (src, setImage, setLoading) => {
        const loadingImage = new Image();
        loadingImage.src = src;
        loadingImage.onload = () => {
            setImage(loadingImage.src);
            setLoading(false);
        };
    };

    const handleInput = (e) => {
        setPosition(e.target.value)
    }

    useEffect(() => {
        fetchImage(project.before_image, setBeforeImage, setLoadingBefore);
        fetchImage(project.after_image, setAfterImage, setLoadingAfter);
    }, [before_image, after_image]);

    return (
        <div className='img-compare-container' style={{filter: `${(loadingBefore && loadingAfter) ? 'blur(20px)' : ''}`}} >
            <img src={after_image} className='img-compare-after-img' />
            <img src={before_image} className='img-compare-before-img' style={{clipPath: "polygon(0 0," + position + "% 0," + position + "% 100%, 0 100%)"}} />
            <input onInput={handleInput} className='img-compare-slider' type='range' min='0' max='100' value='50' step={0.5} />
            {/* <div className='img-compare-slider-line' style={{"left" : `${position}%`}} ></div> */}
            <div className='img-compare-slider-button' style={{"left" : `${position}%`}} aria-hidden={true} >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" viewBox="0 0 256 256"><path d="M136,40V216a8,8,0,0,1-16,0V40a8,8,0,0,1,16,0ZM96,120H35.31l18.35-18.34A8,8,0,0,0,42.34,90.34l-32,32a8,8,0,0,0,0,11.32l32,32a8,8,0,0,0,11.32-11.32L35.31,136H96a8,8,0,0,0,0-16Zm149.66,2.34-32-32a8,8,0,0,0-11.32,11.32L220.69,120H160a8,8,0,0,0,0,16h60.69l-18.35,18.34a8,8,0,0,0,11.32,11.32l32-32A8,8,0,0,0,245.66,122.34Z"></path></svg>
            </div>
        </div>
    )
};
