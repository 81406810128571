export const welcome_text = [
    {
        "title" : "Welcome to Artisana Visuals",
        "text" : "At Artisana Visuals, we are not just a team of editors; we are architects of visual experiences, dedicated to transforming concepts into captivating realities. Our passion for delivering unparalleled quality is the driving force behind every project we undertake."
    },
    {
        "title" : "Who We Are:",
        "text" : "Artisana Visuals is a tight-knit collective of creative minds, each contributing a unique set of skills to our collaborative efforts. As a team, we understand the power of visuals in storytelling, and we embrace the responsibility of bringing your ideas to life with precision and artistry."
    },
    {
        "title" : "Our Commitment:",
        "text" : "Quality is not just a standard; it's our commitment to excellence. We meticulously craft each visual element, ensuring that every detail contributes to the overall narrative. Our dedication to delivering the best stems from a shared belief that exceptional visuals not only communicate ideas but evoke emotions and leave a lasting impact."
    }
]

export const why_choose_text = [
    {
        "title" : "Collaborative Expertise:",
        "text" : "Our team comprises experienced editors who seamlessly blend their skills to produce visuals that surpass expectations."
    },
    {
        "title" : "Innovation at Core", 
        "text" : "Embracing the latest technologies and design trends, we bring innovation to the forefront, ensuring your visuals are not just current but cutting-edge."
    },
    {
        "title" : "Passion for Perfection:",
        "text" : "We are driven by a relentless pursuit of perfection, ensuring that every project receives the attention and care it deserves."
    }
]