import './ProjectsStyle.css';

const interior_renders_projects = {
    "project-1" : {
        "name" : "Project 1",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_1/interior_render_1.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_1/interior_render_1_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-2" : {
        "name" : "Project 2",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_2/interior_render_2.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_2/interior_render_2_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-3" : {
        "name" : "Project 3",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_3/interior_render_3.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_3/interior_render_3_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-4" : {
        "name" : "Project 4",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_4/interior_render_4.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_4/interior_render_4_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-5" : {
        "name" : "Project 5",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_5/interior_render_5.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_5/interior_render_5_placeholder.jpg"),
        "orientation" : "horizontal"

    },
    "project-6" : {
        "name" : "Project 6",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_6/interior_render_6.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_6/interior_render_6_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-7" : {
        "name" : "Project 7",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_7/interior_render_7.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_7/interior_render_7_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-8" : {
        "name" : "Project 8",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_8/interior_render_8.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_8/interior_render_8_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-9" : {
        "name" : "Project 9",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_9/interior_render_9.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_9/interior_render_9_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-10" : {
        "name" : "Project 10",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_10/interior_render_10.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_10/interior_render_10_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-11" : {
        "name" : "Project 11",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_11/interior_render_11.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_11/interior_render_11_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-12" : {
        "name" : "Project 12",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_12/interior_render_12.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_12/interior_render_12_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-13" : {
        "name" : "Project 13",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_13/interior_render_13.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_13/interior_render_13_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-14" : {
        "name" : "Project 14",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_14/interior_render_14.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_14/interior_render_14_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-15" : {
        "name" : "Project 15",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_15/interior_render_15.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_15/interior_render_15_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-16" : {
        "name" : "Project 16",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_16/interior_render_16.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_16/interior_render_16_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-17" : {
        "name" : "Project 17",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_17/interior_render_17.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_17/interior_render_17_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-18" : {
        "name" : "Project 18",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_18/interior_render_18.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_18/interior_render_18_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-19" : {
        "name" : "Project 19",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_19/interior_render_19.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_19/interior_render_19_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-20" : {
        "name" : "Project 20",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_20/interior_render_20.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_20/interior_render_20_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-21" : {
        "name" : "Project 21",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_21/interior_render_21.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_21/interior_render_21_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-22" : {
        "name" : "Project 22",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_22/interior_render_22.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_22/interior_render_22_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-23" : {
        "name" : "Project 23",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_23/interior_render_23.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_23/interior_render_23_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-24" : {
        "name" : "Project 24",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_24/interior_render_24.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_24/interior_render_24_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-25" : {
        "name" : "Project 25",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_25/interior_render_25.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_25/interior_render_25_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-26" : {
        "name" : "Project 26",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_26/interior_render_26.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_26/interior_render_26_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-27" : {
        "name" : "Project 27",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_27/interior_render_27.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_27/interior_render_27_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-28" : {
        "name" : "Project 28",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_28/interior_render_28.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_28/interior_render_28_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-29" : {
        "name" : "Project 29",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/interior_renders/project_29/interior_render_29.jpg"),
        "image_placeholder" : require("../assets/images/projects/interior_renders/project_29/interior_render_29_placeholder.jpg"),
        "orientation" : "horizontal"
    },
}

const exterior_renders_projects = {
    "project-1" : {
        "name" : "Project 1",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_1/exterior_render_1.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_1/exterior_render_1_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-2" : {
        "name" : "Project 2",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_2/exterior_render_2.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_2/exterior_render_2_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-4" : {
        "name" : "Project 4",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_4/exterior_render_4.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_4/exterior_render_4_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-5" : {
        "name" : "Project 5",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_5/exterior_render_5.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_5/exterior_render_5_placeholder.jpg"),
        "orientation" : "horizontal"

    },
    "project-6" : {
        "name" : "Project 6",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_6/exterior_render_6.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_6/exterior_render_6_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-7" : {
        "name" : "Project 7",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_7/exterior_render_7.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_7/exterior_render_7_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-8" : {
        "name" : "Project 8",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_8/exterior_render_8.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_8/exterior_render_8_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-9" : {
        "name" : "Project 9",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_9/exterior_render_9.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_9/exterior_render_9_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-3" : {
        "name" : "Project 3",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/exterior_renders/project_3/exterior_render_3.jpg"),
        "image_placeholder" : require("../assets/images/projects/exterior_renders/project_3/exterior_render_3_placeholder.jpg"),
        "orientation" : "horizontal"
    },
}

const virtual_staging_projects = {
    "project-1" : {
        "name" : "Project 1",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_1/virtual_staging_1_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_1/virtual_staging_1_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_1/virtual_staging_1_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_1/virtual_staging_1_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-2" : {
        "name" : "Project 2",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_2/virtual_staging_2_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_2/virtual_staging_2_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_2/virtual_staging_2_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_2/virtual_staging_2_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-3" : {
        "name" : "Project 3",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_3/virtual_staging_3_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_3/virtual_staging_3_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_3/virtual_staging_3_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_3/virtual_staging_3_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-4" : {
        "name" : "Project 4",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_4/virtual_staging_4_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_4/virtual_staging_4_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_4/virtual_staging_4_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_4/virtual_staging_4_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-5" : {
        "name" : "Project 5",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_5/virtual_staging_5_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_5/virtual_staging_5_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_5/virtual_staging_5_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_5/virtual_staging_5_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-6" : {
        "name" : "Project 6",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_6/virtual_staging_6_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_6/virtual_staging_6_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_6/virtual_staging_6_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_6/virtual_staging_6_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-7" : {
        "name" : "Project 7",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_7/virtual_staging_7_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_7/virtual_staging_7_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_7/virtual_staging_7_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_7/virtual_staging_7_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-8" : {
        "name" : "Project 8",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_8/virtual_staging_8_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_8/virtual_staging_8_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_8/virtual_staging_8_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_8/virtual_staging_8_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-9" : {
        "name" : "Project 9",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_9/virtual_staging_9_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_9/virtual_staging_9_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_9/virtual_staging_9_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_9/virtual_staging_9_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-10" : {
        "name" : "Project 10",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_10/virtual_staging_10_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_10/virtual_staging_10_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_10/virtual_staging_10_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_10/virtual_staging_10_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-11" : {
        "name" : "Project 11",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_11/virtual_staging_11_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_11/virtual_staging_11_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_11/virtual_staging_11_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_11/virtual_staging_11_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-12" : {
        "name" : "Project 12",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_12/virtual_staging_12_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_12/virtual_staging_12_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_12/virtual_staging_12_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_12/virtual_staging_12_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-13" : {
        "name" : "Project 13",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_13/virtual_staging_13_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_13/virtual_staging_13_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_13/virtual_staging_13_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_13/virtual_staging_13_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-14" : {
        "name" : "Project 14",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_14/virtual_staging_14_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_14/virtual_staging_14_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_14/virtual_staging_14_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_14/virtual_staging_14_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-15" : {
        "name" : "Project 15",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_staging/project_15/virtual_staging_15_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_staging/project_15/virtual_staging_15_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_staging/project_15/virtual_staging_15_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_staging/project_15/virtual_staging_15_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
}

const virtual_renovations_projects = {
    "project-1" : {
        "name" : "Project 1",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_renovations/project_1/virtual_renovation_1_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_renovations/project_1/virtual_renovation_1_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_renovations/project_1/virtual_renovation_1_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_renovations/project_1/virtual_renovation_1_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-2" : {
        "name" : "Project 2",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_renovations/project_2/virtual_renovation_2_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_renovations/project_2/virtual_renovation_2_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_renovations/project_2/virtual_renovation_2_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_renovations/project_2/virtual_renovation_2_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-3" : {
        "name" : "Project 3",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_renovations/project_3/virtual_renovation_3_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_renovations/project_3/virtual_renovation_3_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_renovations/project_3/virtual_renovation_3_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_renovations/project_3/virtual_renovation_3_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-4" : {
        "name" : "Project 4",
        "type" : "comparison-image",
        "before_image" : require("../assets/images/projects/virtual_renovations/project_4/virtual_renovation_4_before.jpg"),
        "before_placeholder" : require("../assets/images/projects/virtual_renovations/project_4/virtual_renovation_4_before_placeholder.jpg"),
        "after_image" : require("../assets/images/projects/virtual_renovations/project_4/virtual_renovation_4_after.jpg"),
        "after_placeholder" : require("../assets/images/projects/virtual_renovations/project_4/virtual_renovation_4_after_placeholder.jpg"),
        "orientation" : "horizontal"
    },
}

const three_d_floor_plans_projects = {
    "project-1" : {
        "name" : "Project 1",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_1/3d_floor_plan_1.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_1/3d_floor_plan_1_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-3" : {
        "name" : "Project 3",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_3/3d_floor_plan_3.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_3/3d_floor_plan_3_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-7" : {
        "name" : "Project 7",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_7/3d_floor_plan_7.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_7/3d_floor_plan_7_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-5" : {
        "name" : "Project 5",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_5/3d_floor_plan_5.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_5/3d_floor_plan_5_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-6" : {
        "name" : "Project 6",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_6/3d_floor_plan_6.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_6/3d_floor_plan_6_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-4" : {
        "name" : "Project 4",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_4/3d_floor_plan_4.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_4/3d_floor_plan_4_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-8" : {
        "name" : "Project 8",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_8/3d_floor_plan_8.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_8/3d_floor_plan_8_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-9" : {
        "name" : "Project 9",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_9/3d_floor_plan_9.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_9/3d_floor_plan_9_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-10" : {
        "name" : "Project 10",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_10/3d_floor_plan_10.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_10/3d_floor_plan_10_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-2" : {
        "name" : "Project 2",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/3d_floor_plans/project_2/3d_floor_plan_2.jpg"),
        "image_placeholder" : require("../assets/images/projects/3d_floor_plans/project_2/3d_floor_plan_2_placeholder.jpg"),
        "orientation" : "vertical"
    },
}

const two_d_floor_plans_projects = {
    "project-1" : {
        "name" : "Project 1",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/2d_floor_plans/project_1/2d_floor_plan_1.jpg"),
        "image_placeholder" : require("../assets/images/projects/2d_floor_plans/project_1/2d_floor_plan_1_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-2" : {
        "name" : "Project 2",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/2d_floor_plans/project_2/2d_floor_plan_2.jpg"),
        "image_placeholder" : require("../assets/images/projects/2d_floor_plans/project_2/2d_floor_plan_2_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-3" : {
        "name" : "Project 3",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/2d_floor_plans/project_3/2d_floor_plan_3.jpg"),
        "image_placeholder" : require("../assets/images/projects/2d_floor_plans/project_3/2d_floor_plan_3_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-4" : {
        "name" : "Project 4",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/2d_floor_plans/project_4/2d_floor_plan_4.jpg"),
        "image_placeholder" : require("../assets/images/projects/2d_floor_plans/project_4/2d_floor_plan_4_placeholder.jpg"),
        "orientation" : "vertical"
    },
    "project-5" : {
        "name" : "Project 5",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/2d_floor_plans/project_5/2d_floor_plan_5.jpg"),
        "image_placeholder" : require("../assets/images/projects/2d_floor_plans/project_5/2d_floor_plan_5_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-6" : {
        "name" : "Project 6",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/2d_floor_plans/project_6/2d_floor_plan_6.jpg"),
        "image_placeholder" : require("../assets/images/projects/2d_floor_plans/project_6/2d_floor_plan_6_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-7" : {
        "name" : "Project 7",
        "type" : "normal-image",
        "image" : require("../assets/images/projects/2d_floor_plans/project_7/2d_floor_plan_7.jpg"),
        "image_placeholder" : require("../assets/images/projects/2d_floor_plans/project_7/2d_floor_plan_7_placeholder.jpg"),
        "orientation" : "horizontal"
    },
}

const virtual_tour_projects = {
    "project-1" : {
        "name" : "Bathroom",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR4f?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/bathroom/bathroom_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/bathroom/bathroom_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-2" : {
        "name" : "Bedroom 1",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7cR4b?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/bedroom/bedroom_1_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/bedroom/bedroom_1_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-3" : {
        "name" : "Bedroom 2",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR4s?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/bedroom/bedroom_2_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/bedroom/bedroom_2_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-4" : {
        "name" : "Dining Room",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR4B?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/dining_room/dining_room_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/dining_room/dining_room_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-5" : {
        "name" : "Entry",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR7h?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/entry/entry_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/entry/entry_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-6" : {
        "name" : "Flex Room 1",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR7G?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/flex_room/flex_room_1_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/flex_room/flex_room_1_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-7" : {
        "name" : "Flex Room 2",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR7J?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/flex_room/flex_room_2_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/flex_room/flex_room_2_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-8" : {
        "name" : "Foyer",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR7c?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/foyer/foyer_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/foyer/foyer_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-9" : {
        "name" : "Garage 1",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR7T?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/garage/garage_tour_1.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/garage/garage_tour_1_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-10" : {
        "name" : "Garage 2",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR7s?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/garage/garage_tour_2.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/garage/garage_tour_2_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-11" : {
        "name" : "Great Room 1",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR7B?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/great_room/great_room_1_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/great_room/great_room_1_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-12" : {
        "name" : "Great Room 2",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cRN5?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/great_room/great_room_2_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/great_room/great_room_2_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-13" : {
        "name" : "Great Room 3",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cRN9?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/great_room/great_room_3_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/great_room/great_room_3_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-14" : {
        "name" : "Kitchen",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cRND?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/kitchen/kitchen_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/kitchen/kitchen_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    },
    "project-15" : {
        "name" : "Wardrobe",
        "type" : "tour",
        "element" : <iframe className='tourElement' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cRNg?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>,
        "image" : require("../assets/images/projects/virtual_tour/wardrobe/wardrobe_tour.jpg"),
        "placeholder" : require("../assets/images/projects/virtual_tour/wardrobe/wardrobe_tour_placeholder.jpg"),
        "orientation" : "horizontal"
    }
}

const animations_projects = {
    "project-1" : {
        "name" : "Anim Project 1",
        "type" : "video",
        "image" : require("../assets/videos/projects/animations/animation_1/animation_1_image.jpg"),
        "placeholder" : require("../assets/videos/projects/animations/animation_1/animation_1_placeholder.jpg"),
        "video" : require("../assets/videos/projects/animations/animation_1/animation_1_video.mp4"),
        "orientation" : "horizontal"
    },
    "project-2" : {
        "name" : "Anim Project 2",
        "type" : "video",
        "image" : require("../assets/videos/projects/animations/animation_2/animation_2_image.jpg"),
        "placeholder" : require("../assets/videos/projects/animations/animation_2/animation_2_placeholder.jpg"),
        "video" : require("../assets/videos/projects/animations/animation_2/animation_2_video.mp4"),
        "orientation" : "horizontal"
    }
}

const three_sixty_virtual_staging_projects = {

}

export const projects_data = {
    "interior-renders" : {
        "name" : "Interior Renders",
        "project_list" : interior_renders_projects
    },
    "exterior-renders" : {
        "name" : "Exterior Renders",
        "project_list" : exterior_renders_projects
    },
    "virtual-staging" : {
        "name" : "Virtual Staging",
        "project_list" : virtual_staging_projects
    },
    "virtual-renovations" : {
        "name" : "Virtual Renovations",
        "project_list" : virtual_renovations_projects
    },
    "3d-floor-plans" : {
        "name" : "3D Floor Plans",
        "project_list" : three_d_floor_plans_projects
    },
    "2d-floor-plans" : {
        "name" : "2D Floor Plans",
        "project_list" : two_d_floor_plans_projects
    },
    "virtual-tour" : {
        "name" : "Virtual Tour",
        "project_list" : virtual_tour_projects
    },
    "animations" : {
        "name" : "Animations",
        "project_list" : animations_projects
    },
    "360-virtual-staging" : {
        "name" : "360 Virtual Staging",
        "project_list" : three_sixty_virtual_staging_projects
    }
}