import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBnRyzoVP9j0rYp5sq_FoX29XheoPzusTQ",
  authDomain: "artisana-visuals.firebaseapp.com",
  projectId: "artisana-visuals",
  storageBucket: "artisana-visuals.appspot.com",
  messagingSenderId: "870466902346",
  appId: "1:870466902346:web:ea39dcc3dcabffebfb89b3"
};

const app = initializeApp(firebaseConfig);

export const storageDB = getStorage(app)
export const realtimeDB = getDatabase(app);