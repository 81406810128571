// Dependencies
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';

// Styles 
import '../../App.css'
import './PageTitle.css';

function PageTitle(props) {
  const [displayPath, setDisplayPath] = useState(true);

  const checkSize = () => {
    if(window.innerWidth <= 960) {
      setDisplayPath(false)
    }
    else {
      setDisplayPath(true)
    }
  };

  useEffect(() => {
    checkSize()
  }, [])

  window.addEventListener('resize', checkSize)

  return (
    <div>
        <div className='about-title-container'> 
            <div className='about-title-wrapper'>
                <h1>{props.text}</h1>
                {displayPath && <h2>
                <Link to='/' >Home</Link>{(props.group !== undefined) && (<> / <Link to={'/' + props.group.path}>{props.group.name}</Link></>) } / {props.path}</h2>}
            </div>
        </div>
    </div>
  )
}

export default PageTitle
