// Dependencies
import {React, useEffect, useState} from 'react';

// Styles
import '../../../App.css';
import './Content.css';

// Components
import { ProjectSelectionButton } from '../../../components/buttons/ProjectSelectionButton';
import { ProjectsImages } from '../../../components/images/ProjectsImages';

// Data

import { projects_data } from '../../../data/projects_data'
import { ImageModal } from '../../../components/images/ImageModal';

const projectTypesKeys = Object.keys(projects_data)

function Content(props) {
    const [selection, setSelection] = useState((props.type !== undefined) ? props.type : projectTypesKeys[0]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    
    const projectsValues = Object.values(projects_data[selection].project_list);
  
    const splitArray = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size)
    );

    const clickHandle = (key) => {
      setSelection(key);
    } 

    const selectProject = (project) => {
        setModalOpen(true);
        setSelectedProject(project);
    }

    useEffect(() => {
        if(isModalOpen) {
            document.body.style.overflow = "hidden";
        }
        else{
            document.body.style.overflow = "auto";
        }
    }, [isModalOpen])

    return (
        <div className='projects-content-container'>
            {
                isModalOpen && <ImageModal project={selectedProject} closeClick={() => setModalOpen(false)} />
            }
            <div className='content-selection-buttons-container'>
                {
                    projectTypesKeys.map(
                        (key, index) => {
                            return <ProjectSelectionButton key={index} selectionCondition={key === selection} onClick={() => clickHandle(key)} >
                                { projects_data[key].name }
                            </ProjectSelectionButton>
                        }
                    )
                }
            </div>
            <div className='content-images-row'>
                {
                    splitArray(projectsValues, Math.ceil((projectsValues.length/4))).map(
                        (split_projects, index_1) => {
                            return <div key={selection+"_row_" + index_1} className='content-images-column'>
                                {
                                    split_projects.map(
                                        (project, index_2) => {
                                            return <ProjectsImages key={selection + "_column_" + index_2} project={project} click={() => selectProject(project)}/>;
                                        }
                                    )
                                }
                            </div>
                        }
                    )
                }
            </div>
        </div>
    );
}
    
export default Content;
    