const tour = <iframe width='100%' height='415' frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cR4B?logo=1&info=1&fs=0&vr=0&sd=1&gyro=0&autorotate=0.22&thumbs=-1&alpha=0.60&keys=0"></iframe>;

export const services_data = {
    "interior-renders" : {
        "name" : "Interior Renders",
        "id" : "interior-renders",
        "label" : "Interior Renders",
        "description" : "Step inside the heart of design excellence with our Interior Rendering services. We intricately craft realistic depictions that capture the interplay of light, textures, and furnishings, bringing your interior concepts to life. Let clients explore the intimate details of their spaces, fostering a deep connection with the design before it becomes a reality.",
        "type" : "normal-image",
        "image" : require('../assets/images/services/interior_renders/interior_render_1.jpg'),
        "image_placeholder" : require('../assets/images/services/interior_renders/interior_render_1_placeholder.jpg'),
        "path" : "/interior-renders"
    },
    "exterior-renders" : {
        "name" : "Exterior Renders",
        "id" : "exterior-renders",
        "label" : "Exterior Renders",
        "description" : "Immerse your clients in the awe-inspiring vision of their future spaces with our Exterior Rendering services. Our skilled team transforms architectural concepts into vivid, lifelike visuals, showcasing the exterior details, landscaping, and atmosphere. Elevate your presentations and empower clients to envision the full potential of their projects.",
        "type" : "normal-image",
        "image" : require('../assets/images/services/exterior_renders/exterior_render_1.jpg'),
        "image_placeholder" : require('../assets/images/services/exterior_renders/exterior_render_1_placeholder.jpg'),
        "path" : "/exterior-renders"
    },
    "virtual-staging" : {
        "name" : "Virtual Staging",
        "id" : "virtual-staging",
        "label" : "Virtual Staging",
        "description" : "Step into a world of enhanced real estate marketing with our Virtual Staging service. From vacant rooms to empty homes, we bring spaces to life with digitally furnished and decorated visuals. Elevate your property listings, entice potential buyers, and sell faster with our expertly crafted Virtual Staging solutions.",
        "type" : "comparison-image",
        "before_image" : require('../assets/images/services/virtual_staging/virtual_staging_1_before.jpg'),
        "before_placeholder" : require('../assets/images/services/virtual_staging/virtual_staging_1_before_placeholder.jpg'),
        "after_image" : require('../assets/images/services/virtual_staging/virtual_staging_1.jpg'),
        "after_placeholder" : require('../assets/images/services/virtual_staging/virtual_staging_1_placeholder.jpg'),
        "path" : "/virtual-staging"
    },
    "virtual-renovation" : {
        "name" : "Virtual Renovation",
        "id" : "virtual-renovation",
        "label" : "Virtual Renovation",
        "description" : "Revitalize your property with our Virtual Renovation service. Whether it's outdated interiors, tired exteriors, or a blank canvas awaiting transformation, our team breathes new life into your spaces digitally. From modern upgrades to imaginative redesigns, visualize the possibilities and unlock the full potential of your property.",
        "type" : "comparison-image",
        "before_image" : require('../assets/images/services/virtual_renovation/virtual_renovation_1_before.jpg'),
        "before_placeholder" : require('../assets/images/services/virtual_renovation/virtual_renovation_1_before_placeholder.jpg'),
        "after_image" : require('../assets/images/services/virtual_renovation/virtual_renovation_1.jpg'),
        "after_placeholder" : require('../assets/images/services/virtual_renovation/virtual_renovation_1_placeholder.jpg'),
        "path" : "/virtual-renovation"
    },
    "3d-floor-plans" : {
        "name" : "3D Floor Plans",
        "id" : "floor-plans-3d",
        "label" : "3D Floor Plans",
        "description" : "Experience your property like never before with our 3D Floor Plans service. From architectural drafts to fully immersive visualizations, we craft detailed and realistic layouts that provide a clear perspective of your space. Whether you're designing, selling, or leasing, our 3D Floor Plans bring your vision to life, helping you make informed decisions and showcase the unique features of your property with confidence.",
        "type" : "normal-image",
        "image" : require('../assets/images/services/3d_floor_plans/3d_floor_plan_1.jpg'),
        "image_placeholder" : require('../assets/images/services/3d_floor_plans/3d_floor_plan_1_placeholder.jpg'),
        "path" : "/3d-floor-plans"
    },
    "2d-floor-plans" : {
        "name" : "2D Floor Plans",
        "id" : "floor-plans-2d",
        "label" : "2D Floor Plans",
        "description" : "Transform your property into a blueprint for success with our 2D Floor Plans service. From sketches to detailed layouts, we create accurate and visually appealing representations of your space. Whether you're planning renovations, marketing a property, or seeking architectural guidance, our 2D Floor Plans offer clarity and precision, helping you visualize and communicate your ideas effectively.",
        "type" : "normal-image",
        "image" : require('../assets/images/services/2d_floor_plans/2d_floor_plan_1.jpg'),
        "image_placeholder" : require('../assets/images/services/2d_floor_plans/2d_floor_plan_1_placeholder.jpg'),
        "path" : "/2d-floor-plans"
    },
    "virtual-tour" : {
        "name" : "Virtual Tour",
        "id" : "virtual-tour",
        "label" : "Virtual Tour",
        "description" : "Step inside your future with our Virtual Tour service. Explore properties from the comfort of your own home or office with immersive, interactive experiences. Whether you're a real estate agent showcasing listings or a property owner inviting potential buyers, our virtual tours offer a lifelike glimpse into every room and corner, creating a memorable and engaging viewing experience that sets your property apart.",
        "type" : "tour",
        "element" : tour,
        "image" : require('../assets/images/services/virtual_tour/virtual_tour_1.jpg'),
        "image_placeholder" : require('../assets/images/services/virtual_tour/virtual_tour_1_placeholder.jpg'),
        "path" : "/virtual-tour"
    },
    "animations" : {
        "name" : "Animations",
        "id" : "animations",
        "label" : "Animations",
        "description" : "Bring your property to life with our Animations service. From captivating walkthroughs to dynamic flyovers, we craft stunning visual experiences that showcase the unique features and potential of your space. Whether you're unveiling architectural designs, promoting real estate projects, or enhancing presentations, our Animations captivate audiences and leave a lasting impression, transforming your visions into mesmerizing reality.",
        "type" : "video",
        "video" : require('../assets/videos/background.mp4'),
        "path" : "/animations"
    },
    "360-virtual-staging" : {
        "name" : "360 Virtual Staging",
        "id" : "virtual-staging-360",
        "label" : "360 Virtual Staging",
        "description" : "Immerse yourself in the future of real estate marketing with our 360 Virtual Staging service. Step into fully furnished and decorated spaces from every angle, experiencing the warmth and charm of a home without ever setting foot inside. Whether you're a real estate professional seeking to elevate listings or a homeowner looking to showcase the potential of your property, our 360 Virtual Staging creates immersive experiences that engage and entice viewers, setting your listings apart in a competitive market.",
        "type" : "normal-image",
        "image" : require('../assets/images/services/360_virtual_staging/360_virtual_staging.jpg'),
        "image_placeholder" : require('../assets/images/services/360_virtual_staging/360_virtual_staging_placeholder.jpg'),
        "path" : "/360-virtual-staging"
    }
}   