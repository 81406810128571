// Dependencies
import React from 'react';

// Styles
import '../../../App.css';
import './Content.css';

// Components

// Sections

function Content() {
  return (
    <div className='error-page-content-container'>
        <h1>404 Error</h1>
        <h1>Requested Page Not Found</h1>
    </div>
  )
}

export default Content
