// Dependencies
import React from 'react';
import { useParams } from 'react-router-dom'

// Styles
import '../../App.css';

// Components
import PageTitle from '../../components/global/PageTitle';
import Content from './sections/Content';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';
import FloatingButton from '../../components/global/FloatingButton';
import { projects_data } from '../../data/projects_data'
import ErrorPage from '../error_page/ErrorPage';

// Data

// Sections

function ProjectsListPage() {
    const { typeID } = useParams();

    if (!Object.keys(projects_data).includes(typeID) && typeID !== undefined) {
        return (
            <>
                <ErrorPage />
            </>
        )
    }
    else {
        return (
            <>
                <Navbar />
                <PageTitle text='Our Projects' path='Projects' />
                <Content type={typeID} />
                <Footer />
                <FloatingButton path='/contact' />
            </>
        );
    }
}

export default ProjectsListPage;