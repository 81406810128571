// Dependencies
import React, { useEffect, useState } from 'react';

// Styles
import './ImageModal.css';
import BlurryLoadingImage from './BlurryLoadingImage';
import { CompareImage } from './CompareImage';

function getComponentType(project) {
    switch (project.type) {
        case 'normal-image':
            return <BlurryLoadingImage image={project.image} preview={project.image_placeholder} />;
        case 'comparison-image':
            return <CompareImage project={project} />;
        case 'tour':
            return project.element;
        case 'video':
            return <video className='modal-video' src={project.video} autoPlay loop muted playsinline preload='meta' />;
    }
}

export const ImageModal = ({
    project,
    closeClick
}) => {
    const componentType = getComponentType(project);

    const adjustTourSize = () => {
        const r = document.querySelector(':root');
        r.style.setProperty('--tourWidth', `${window.innerWidth - 100}px`);
    }

    useEffect(() => {
        if(project.type === 'tour'){
            adjustTourSize()
            window.addEventListener('resize', adjustTourSize);
        }
    }, [])

    return (
        <div className='modal-overlay'>
            <div className='modal-close'>
                <i className={'fas fa-times modal-close-button'} onClick={closeClick} />
            </div>
            <div className='modal-image-wrapper'>
                <div className='modal-image-container'>
                    <figure className={`modal-image modal-image-zoom ${'image-' + project.orientation + '-orientation'}`}>
                        {componentType}
                    </figure>
                </div>
            </div>
        </div>
    )
};
