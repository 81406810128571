// Dependencies
import React from 'react'

// Styles 
import '../../../App.css'
import './Title.css';

function Title() {
  return (
    <div className='faq-title-container'> 
        <div className='faq-title-wrapper'>
            <h1>Frequently Asked Questions</h1>
            <h2>Find your answers</h2>
        </div>
    </div>
  )
}

export default Title
