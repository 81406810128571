// Dependencies
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Styles
import '../../App.css';

// Components
import PageTitle from '../../components/global/PageTitle';
import Service from './sections/Service';

// Data
import { services_data } from '../../data/services_data';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';
import FloatingButton from '../../components/global/FloatingButton';

// Sections

function ServicesListPage() {
    const {serviceID} = useParams();

    useEffect(
        () => {
            if(serviceID !== undefined){
                const section = document.querySelector(`#${serviceID}`);
                section.scrollIntoView(true);
            }
        }, []
    )

    return (
        <>
            <Navbar />
            <PageTitle text='Our Services' path='Services' />
            {
                Object.values(services_data).map(
                    (service, index) => {
                        return <Service key={index} service={service} buttonText={"Read More"} backgroundLighter={index%2===1} imageLeft={index%2===1} />
                    }
                )
            }
            <Footer />
            <FloatingButton path='/contact' />
        </>
    );
}

export default ServicesListPage;