// Dependencies
import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import './CardItem.css'
import BlurryLoadingImage from '../images/BlurryLoadingImage';

function getComponentType(service) {
  switch (service.type) {
      case 'normal-image': 
          return <BlurryLoadingImage imageStyleClass={'cards__item__img'} image={service.image} preview={service.image_placeholder} />;
      case 'tour': 
          return <BlurryLoadingImage imageStyleClass={'cards__item__img'} image={service.image} preview={service.image_placeholder} />;
      case 'video':
          return  <div className='cards__item__img__wrapper'>
            <video className='cards__item__img' src={ service.video } autoPlay loop muted playsinline preload='meta' />
          </div>;
      case 'comparison-image': 
          return <BlurryLoadingImage imageStyleClass={'cards__item__img'} image={service.after_image} preview={service.after_placeholder} />;
  }
}

function CardItem(props) {
  return (
    <>
      <li className='cards__item' data-aos="fade-up" data-aos-delay={props.delay}>
        {/* <Link className='cards__item__link' to={"/service" + props.service.path}> */}
        <Link className='cards__item__link' to={`/services-list/${props.service.id}`}>
          <figure className='cards__item__pic-wrap' >
            { getComponentType(props.service) }
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>
              {props.service.name}
            </h5>
          </div>
        </Link>
      </li>
    </>
  )
}

export default CardItem
