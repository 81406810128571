// Dependencies
import { React, useState, useEffect } from 'react';

// Styles
import './Slideshow.css';
import BlurryLoadingImage from './BlurryLoadingImage';

export const Slideshow = ({
  slideshowData,
  delay
}) => {
  const [index, setIndex] = useState(0);

  const changeSlide = () => {
    const nextIndex = index === slideshowData.length - 1 ? 0 : index + 1;

    document.getElementById(`slide_${index}`).style.opacity = 0
    document.getElementById(`slide_${nextIndex}`).style.opacity = 1

    setIndex(nextIndex)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      changeSlide();
    }, delay);

    return () => clearInterval(interval);
  }, [index]); 

  return (
    <div className="slideshow" data-aos="fade-right">
      <div className="slideshow-slider">
        {
          slideshowData.map((slide, slide_index) => {
            {/* return <img className='slide' alt={'Slide ' + slide_index} src={slide.image} key={slide_index} id={`slide_${slide_index}`} style={{opacity: `${slide_index === 0 ? 1 : 0}`}} />  */}
            return <BlurryLoadingImage divStyleClass={'slide'} image={slide.image} preview={slide.placeholder} slideshow={`slide_${slide_index}`} opacity={`${slide_index === 0 ? 1 : 0}`}/>;
          })
        }
      </div>
    </div>
  )
};
