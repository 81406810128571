// Dependencies
import React from 'react';

// Styles
import '../../../App.css';
import './Service.css';
import { ReadMoreButton } from '../../../components/buttons/ReadMoreButton';
import BlurryLoadingImage from '../../../components/images/BlurryLoadingImage';
import { ComparisonImage } from '../../../components/images/ComparisonImage';
import { CompareImage } from '../../../components/images/CompareImage';

// Components
function getComponentType(service) {
  switch (service.type) {
      case 'normal-image':
          return <BlurryLoadingImage image={service.image} preview={service.image_placeholder} />;
      case 'comparison-image':
          return <CompareImage project={service} />;
      case 'tour':
          return service.element;
      case 'video':
          return <video className='project-video' src={ service.video } autoPlay loop muted preload='meta' />;
      default: 
          return
  }
}

// Data 

function Service(props) {
  const imageFade = props.imageLeft===true ? "fade-right" : "fade-left"
  const textFade = props.imageLeft===true ? "fade-left" : "fade-right"

  return (
    <div id={props.service.id} className={`service-section-container ${props.backgroundLighter===true ? 'service-section-color-lighter' : 'service-section-color-darker'}`}>
        <div className='service-section-content-container'>
            <div className='service-section-content-wrapper'>
              <h1 data-aos={textFade} >{props.service.name}</h1>
              <p data-aos={textFade} data-aos-delay={300} >{props.service.description}</p>

              {/* <ReadMoreButton path={'/service'+props.service.path} animation={textFade} delay={600}>{props.buttonText}</ReadMoreButton> */}

            </div>
        </div>
        <figure data-aos={imageFade} className={`service-section-image-container ${props.imageLeft===true ? 'service-image-left' : ''}`}>
          {
            getComponentType(props.service)
          }
        </figure>
    </div>
  );
}

export default Service;
