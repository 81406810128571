// Dependencies
import React from 'react'

// Styles 
import '../../../App.css'
import './Main.css';
import { FAQuestion } from '../../../components/fields/FAQuestion';

import { questions_data } from '../data/questions_data';

function Main() {
  return (
    <div className='faq-main-container'> 
        {
          questions_data.map((faq, index_1) => {
            return <div key={'div_' + index_1} className='faq-question-group'>
                      <h1 data-aos="fade-right" data-aos-duration="1000">{faq.faq_group}</h1>
                      <div className='faq-questions-list'>
                        {
                          faq.question_list.map((question, index_2) => {
                            return <FAQuestion key={'question_' + index_2} question={question.question} answer={question.answer} />
                          })
                        }
                      </div>
                  </div>
          })
        }
    </div>
  )
}

export default Main
