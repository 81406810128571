// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Styles
import './App.css'

// Components
import ScrollToTop from './components/global/ScrollToTop';

// Pages
import HomePage from './pages/home_page/HomePage';
import AboutPage from './pages/about_page/AboutPage';
import ServicesListPage from './pages/services_list_page/ServicesListPage';
import ServiceItemPage from './pages/service_item_page/ServiceItemPage';
import ProjectsListPage from './pages/projects_list_page/ProjectsListPage';
import ProjectItemPage from './pages/project_item_page/ProjectItemPage';
import ErrorPage from './pages/error_page/ErrorPage'
import ContactPage from './pages/contact_page/ContactPage';
import FAQPage from './pages/faq_page/FAQPage';
import TermsOfServicePage from './pages/tos_page/TermsOfServicePage';
import TeamPage from './pages/team_page/TeamPage';
import Loading from './components/loading/Loading';

function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
      setTimeout(() => setLoading(false), 3300)
  }, [])
  if (loading) {
      return <Loading/>
  }
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' exact element={<HomePage />} />
          <Route path='/about' exact element={<AboutPage />} />
          <Route path='/contact' exact element={<ContactPage />} />
          <Route path='/services-list' exact element={<ServicesListPage />} />
          <Route path='/services-list/:serviceID' exact element={<ServicesListPage />} />
          <Route path='/projects-list' exact element={<ProjectsListPage />} />
          {/* <Route path='/projects-list/:typeID' exact element={<ProjectsListPage />} /> */}
          {/* <Route path='/project/:typeID/:projectID' exact element={<ProjectItemPage />} /> */}
          <Route path='/faq' exact element={<FAQPage />} />
          {/* <Route path='/terms-of-service' exact element={<TermsOfServicePage />} />
          <Route path='/team' exact element={<TeamPage />} /> */}
          <Route path='*' exact element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
