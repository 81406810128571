// Dependencies
import React from 'react';

// Styles
import './CircularRadioButton.css';

export const CircularRadioButton = ({
    selectionCondition,
    onClick,
    children,
    delay
}) => {
    return (
        <div data-aos="flip-right" data-aos-delay={delay} data-aos-duration="2000" className='complete-radio-button'>
            <button className={`radio-info-button  ${selectionCondition ?  'selected-radio-button' : ''}`} onClick={onClick} >
                {children}
            </button>
            <span className={`selected-bottom-line ${selectionCondition ? 'display-line' : ''}`}/>
        </div>
    )
};
