export const radio_buttons_data = {
    "brief" : {
        "name" : "Brief Info",
        "text" : "Welcome to Artisana Visuals, where collaboration begins. This is the stage where you share your project details with us. Whether you're a real estate agent, interior designer, or architect, we're here to listen to your needs and bring your ideas to life. Let's get started - share your project details, and let's collaborate to make it a reality."
    },
    "creation" : {
        "name" : "Creation Stage",
        "text" : "During the creation stage, our talented team of designers and artists will work closely with you to bring your ideas to fruition. We'll start by gathering all the necessary information about your project, including your goals, preferences, and any specific requirements. From there, we'll use our expertise and creativity to craft visual solutions that meet your needs and exceed your expectations."
    },
    "feedback" : {
        "name" : "Feedback Stage",
        "text" : "Your feedback is crucial to the success of your project, which is why we value your input every step of the way. During the feedback stage, we'll present our initial concepts and designs for your review. We encourage you to provide honest and constructive feedback so that we can make any necessary adjustments and ensure that the final result aligns perfectly with your vision."
    },
    "final" : {
        "name" : "Final High Resolution",
        "text" : "Once we've incorporated your feedback and made any revisions, we'll proceed to the final high-resolution stage. This is where we put the finishing touches on your project and prepare it for delivery. You can expect to receive high-quality, professional-grade visuals that are ready to be used in your marketing materials, presentations, or any other applications."
    }
}