// Dependencies
import React from 'react';

// Styles
import '../../App.css';
import Title from './sections/Title';
import Main from './sections/Main';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';
import FloatingButton from '../../components/global/FloatingButton';

// Components

// Sections

function FAQPage() {
    return (
        <>  
            <Navbar />
            <Title />
            <Main />
            <Footer />
            <FloatingButton path='/contact' />
        </>
    );
}

export default FAQPage;