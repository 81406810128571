// Dependencies
import React from 'react';

// Styles
import '../../../App.css';
import './Hero.css';

// Components
import { DefaultButton } from '../../../components/buttons/DefaultButton';

function Hero() {
  return (
    <div className='hero-container'>
      <video src={require('../../../assets/videos/background.mp4')} autoPlay loop muted playsinline preload='meta' />

      <h1>Bring Your Vision to Life</h1>
      <p>Let's create something extraordinary together</p>
      <div className='hero-btns'>
        <DefaultButton className='btns' buttonStyle='btn--outline' buttonSize='btn--large' linkTo='/contact'>Contact Us</DefaultButton>
      </div>
    </div>
  );
}

export default Hero;
