export const footer_data = [
    {
        "title" : "About Us",
        "data" :  [
            // {
            //     "text" : "Terms of Service",
            //     "path" : "terms-of-service"
            // },
            // {
            //     "text" : "The Team",
            //     "path" : "team"
            // },
            {
                "text" : "FAQ",
                "path" : "faq"
            }
        ]
    },
    {
        "title" : "Contact Us",
        "data" : [
            {
                "text" : "Contact",
                "path" : "contact"
            }
        ]
    }
]