// Dependencies
import React, { useState, useRef } from 'react';

// Styles
import '../../App.css'
import './FileInputField.css';

export const FileInputField = ({
    files,
    setFiles
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    function selectFiles(){
        fileInputRef.current.click();
    }

    function onFileSelect(event){
        const selectedFiles = event.target.files;
        
        if(selectedFiles.length === 0) 
            return;

        for(let i=0; i < selectedFiles.length; i++){
            if(!files.some((e) => e.name === selectedFiles[i].name)) {
                setFiles((prevFiles) => [
                    ...prevFiles,
                    {
                        name: selectedFiles[i].name,
                        file: selectedFiles[i]
                    }
                ])
            }
        }
    }

    function deleteFile(index){
        setFiles((prevFiles) => [
            ...prevFiles.filter((_, i) => i !== index)
        ])
    }

    function onDragOver(event){
        event.preventDefault();
        setIsDragging(true);
        event.dataTransfer.dropEffect = "copy";
    }

    function onDragLeave(event){
        event.preventDefault();
        setIsDragging(false);
    }

    function onDrop(event){
        const selectedFiles = event.dataTransfer.files;

        event.preventDefault();
        setIsDragging(false);
        
        for(let i=0; i < selectedFiles.length; i++){
            if(!files.some((e) => e.name === selectedFiles[i].name)) {
                setFiles((prevFiles) => [
                    ...prevFiles,
                    {
                        name: selectedFiles[i].name,
                        file: selectedFiles[i]
                    }
                ])
            }
        }
    }

    return (
        <div className='file-input-field'>
            <div className='file-input-field-top'>
                <p>Drag & Drop File to Upload</p>
            </div>
            <div className='file-input-field-drag-area' onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                {
                    isDragging ? (
                        <span className='file-input-field-select'>
                            Drop Files Here
                        </span>
                    ) :
                    (
                        <>
                            Drag & Drop Files Here or {" "}
                            <span className='file-input-field-select' onClick={selectFiles}>
                                Browse
                            </span>
                        </>
                    )
                }
                <input name='file' type='file' multiple className='file-input-field-field' ref={fileInputRef} onChange={onFileSelect}></input>
            </div>
            <div className='file-input-field-container'>
                {
                    files.map((file, index) => {
                        return <div key={index} className='file-input-field-image'>
                            <span className='file-input-field-delete' onClick={() => deleteFile(index)}>&times;</span>
                            <div className='file-input-field-info'>
                                <i className="fa-solid fa-file fa-2xl"></i>
                                <div className='file-input-field-file-name'>{file.name}</div>
                            </div>
                        </div>
                    })            
                }
            </div>
        </div>
    )
};
