// Dependencies
import React from 'react';

// Styles
import '../../App.css';

// Components

// Sections
import Hero from './sections/Hero';
import About from './sections/About';
import Cards from './sections/Cards'
import Radio from './sections/Radio';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';
import FloatingButton from '../../components/global/FloatingButton';

function HomePage() {
    return (
        <>
            <Navbar />
            <Hero /> 
            <About />
            <Cards />
            <Radio />
            <Footer />
            <FloatingButton path='/contact' />
        </>
    );
}

export default HomePage;