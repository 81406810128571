export const questions_data = [
    {
        'faq_group' : 'Most frequently asked questions about Artisana Visuals:',
        'question_list' : [
            {
                "question" : "What services does Artisana Visuals offer?",
                "answer" : "Our company offers a range of services tailored to the real estate industry, including interior and exterior renders, virtual staging, virtual renovation, 2D and 3D floor plans, virtual tours, animations, and 360 virtual staging. These services are designed to help clients showcase their properties effectively and attract potential buyers or tenants."
            },
            {
                "question" : "What sets Artisana Visuals apart from it's competitors?",
                "answer" : "At Artisana Visuals, we stand out from the competition with our commitment to excellence, innovation, and personalized service. Our team of experts combines cutting-edge technology with creative expertise to deliver unparalleled solutions tailored to your unique needs. From meticulous attention to detail to seamless integration of client feedback, we go above and beyond to ensure your complete satisfaction. Experience the difference with Artisana Visuals and elevate your property marketing to new heights."
            },
            {
                "question" : "What types of clients does Artisana Viusals work with?",
                "answer" : "Our company works with a diverse range of clients within the real estate industry, including real estate agents, property developers, homeowners, architects, interior designers, and marketing agencies. We cater to clients of all sizes and scales, from individual homeowners to large-scale property developers."
            },
            {
                "question" : "How does Artisana Visuals ensure quality and customer satisfaction?",
                "answer" : "Quality and customer satisfaction are our top priorities. We achieve this through a combination of skilled professionals, advanced technology, and a commitment to excellence. Our team works closely with clients to understand their needs and preferences, providing personalized solutions and unlimited revisions until they are completely satisfied with the results."
            },
            {
                "question" : "Can your services be customized to meet specific requirements?",
                "answer" : "Absolutely! We understand that every client and property is unique, which is why we offer customizable solutions to meet specific requirements. Whether it's adjusting virtual staging to match a certain style, creating custom animations, or incorporating specific features into floor plans, we tailor our services to align with the client's vision and goals."
            },
            {
                "question" : "How can clients get started with your services?",
                "answer" : "Getting started with our services is simple. Clients can reach out to us through our website or contact us directly to discuss their project requirements. We offer free consultations to assess their needs, provide recommendations, and offer quotes. Once the project scope is agreed upon, we begin the process, keeping clients informed and involved every step of the way." 
            }
        ]
    },
    {
        'faq_group' : 'Most frequently asked questions about Interior and Exterior renders:',
        'question_list' : [
            {
                "question" : "What are Interior and Exterior renders?",
                "answer" : "Interior and exterior renders are photorealistic visualizations of a property's interior and exterior spaces. These digital images accurately depict architectural details, materials, lighting, and landscaping, providing a realistic preview of the finished project."
            },
            {
                "question" : "How can Interior and Exterior renders benefit my project?",
                "answer" : "Interior and exterior renders are invaluable tools for architects, designers, and developers to communicate their vision and showcase the potential of a property. They help clients visualize the final design, make informed decisions, and gain approval from stakeholders."
            },
            {
                "question" : "Can I make changes to the renders before finalization?",
                "answer" : "Yes, absolutely! We welcome client feedback and revisions to ensure the renders align with your expectations and requirements. Our goal is to deliver results that exceed your vision and leave you completely satisfied with the final product."
            },
            {
                "question" : "What types of properties can you create interior and exterior renders for?",
                "answer": "We can create interior and exterior renders for various types of properties, including residential homes, commercial buildings, hospitality venues, and more. Our team has experience working on projects of all sizes and scales, from single-family homes to large-scale developments."
            },
            {
                "question" : "Do you offer customization options for interior and exterior renders?",
                "answer": "Yes, we offer a range of customization options to ensure that the renders meet your specific needs and preferences. Whether you want to incorporate specific design elements, adjust lighting and materials, or showcase different landscaping options, we can tailor the renders to align with your vision."
            },
            {
                "question" : "Can you provide both daytime and nighttime renders?",
                "answer" : "Yes, we can provide renders for both daytime and nighttime scenes, allowing you to showcase the property in different lighting conditions. Nighttime renders can add ambiance and highlight architectural features such as exterior lighting, landscaping, and interior illumination."
            }
        ]
    },
    {
        'faq_group' : 'Most frequently asked questions about Virtual Staging:',
        'question_list' : [
            {
                "question" : "What is Virtual Staging?",
                "answer" : "Virtual staging is the process of digitally adding furniture, decor, and other elements to empty or partially furnished spaces in photographs. It enhances the visual appeal of a property and helps potential buyers or tenants envision the space's potential."
            },
            {
                "question" : "How does Virtual Staging differ from traditional staging?",
                "answer" : "Traditional staging involves physically furnishing a property with real furniture and decor, which can be time-consuming and expensive. Virtual staging, on the other hand, is cost-effective, efficient, and offers endless design possibilities without the need for physical furniture."
            },
            {
                "question" : "Can Virtual Staging be done for both residential and commercial properties?",
                "answer" : "Yes, virtual staging can be applied to a variety of properties, including residential homes, apartments, condos, commercial spaces, and more. Whether it's a vacant house, a retail storefront, or an office space, virtual staging can enhance the visual appeal and marketability of any property."
            },
            {
                "question" : "What types of furniture and decor can be included in virtual staging?",
                "answer" : "Virtual staging offers endless possibilities in terms of furniture styles, decor themes, and design elements. Our extensive library includes a wide range of furniture pieces, decor items, and accessories to suit different tastes and preferences. Whether you prefer modern, traditional, contemporary, or eclectic styles, we can customize the virtual staging to align with your aesthetic vision."
            },
            {
                "question" : "How long does virtual staging take, and what is the process?",
                "answer" : "The turnaround time for virtual staging depends on the size and complexity of the project. Typically, we aim to deliver virtual staging results within 2-3 business days after receiving the photographs and project requirements. The process involves selecting suitable furniture and decor items, digitally placing them in the photograph, and making any necessary adjustments to achieve the desired look."  
            },
        ]
    },
    {
        'faq_group' : 'Most frequently asked questions about Virtual Renovation:',
        'question_list' : [
            {
                "question" : "What is Virtual Renovation?",
                "answer" : "Virtual renovation involves digitally updating and enhancing the appearance of a property without the need for physical renovations. It allows clients to visualize potential changes such as paint colors, flooring, fixtures, and furniture arrangements before making any actual alterations."
            },
            {
                "question" : "How can virtual renovation benefit my property?",
                "answer" : "Virtual renovation offers numerous benefits, including cost savings, time efficiency, and risk reduction. It allows clients to experiment with different design options and make informed decisions without the expense or disruption of traditional renovations."
            },
            {
                "question" : "Can virtual renovation be done for both interior and exterior spaces?",
                "answer" : "Yes, virtual renovation can be applied to both interior and exterior spaces of a property. Whether you're looking to update the kitchen, revamp the landscaping, or modernize the facade, virtual renovation offers endless possibilities for transformation."
            },
            {
                "question" : "What types of renovations can you simulate through virtual renovation?",
                "answer" : "We can simulate a wide range of renovations through virtual renovation, including interior updates such as paint color changes, flooring replacements, fixture upgrades, and furniture rearrangements. We can also simulate exterior renovations such as landscaping changes, facade updates, and outdoor amenity enhancements."
            },
            {
                "question" : "Can you provide before-and-after comparisons for virtual renovation projects?",
                "answer" : "Yes, we can provide before-and-after comparisons to illustrate the transformation achieved through virtual renovation. These comparisons can be valuable for clients to visualize the potential impact of proposed changes and make informed decisions about their renovation projects."
            },
            {
                "question": "Can virtual renovation help me explore different design options before committing to a renovation plan?",
                "answer": "Yes, virtual renovation is an excellent tool for exploring different design options and experimenting with various renovation ideas before committing to a final plan. It allows you to visualize how different changes will look and feel within the space, helping you make decisions with confidence."
            }
        ]
    },
    {
        'faq_group' : 'Most frequently asked questions about 2D and 3D Floor Plans:',
        'question_list' : [
            {
                "question" : "What are 2D and 3D floor plans?",
                "answer" : "2D floor plans are two-dimensional representations of a property's layout, showing the arrangement of rooms, walls, doors, and windows. 3D floor plans provide a three-dimensional view of the space, offering a more realistic and immersive experience."
            },
            {
                "question" : "How can 2D and 3D floor plans benefit me?",
                "answer" : "2D and 3D floor plans are valuable tools for visualizing and understanding the layout and flow of a property. They help potential buyers or tenants better comprehend the space, making it easier for them to envision themselves living or working there. Additionally, floor plans can be used for marketing purposes to attract more interest and inquiries."
            },
            {
                "question" : "Can I customize the design of my floor plan?",
                "answer" : "Yes, absolutely! We offer customizable floor plan designs to meet your specific needs and preferences. Whether you require certain room dimensions, furniture placement, or branding elements, we can tailor the design to align with your requirements."
            },
            {
                "question" : "What types of floor plan designs can you create?",
                "answers" : "We can create a variety of floor plan designs to suit your needs, including traditional 2D floor plans, 3D floor plans, and interactive floor plans with virtual tours. Our team can also incorporate branding elements, furniture layouts, and other custom features to enhance the presentation of your floor plans."
            },
            {
                "question" : "Do you offer customization options for floor plans?",
                "answer" : "Yes, we offer extensive customization options for floor plans to ensure they meet your specific requirements and preferences. Whether you need to adjust room dimensions, add or remove furniture, or include branding elements, we can tailor the floor plans to align with your vision."
            },
            {
                "question" : "Can you provide floor plans in different file formats for use in marketing materials?",
                "answer" : "Yes, we can provide floor plans in various file formats, including PDF, JPEG, PNG, and CAD formats, to accommodate your specific marketing needs. These files can be easily incorporated into brochures, websites, and other marketing materials to showcase your property effectively."
            }
        ]
    },
    {
        'faq_group' : 'Most frequently asked questions about Virtual Tours:',
        'question_list' : [
            {
                "question" : "What is a Virtual Tour?",
                "answer" : "A virtual tour is a multimedia presentation that allows viewers to explore a property remotely from any location with an internet connection. It typically includes panoramic images, interactive features, and additional information to provide a comprehensive and immersive viewing experience."
            },
            {
                "question" : "How can a virtual tour benefit my property listing?",
                "answer" : "Virtual tours offer numerous benefits for property listings, including increased engagement, wider reach, and higher retention rates. They allow potential buyers or tenants to explore the property at their own pace, gaining a deeper understanding of its layout, features, and amenities."
            },
            {
                "question" : "Can I customize the content of my virtual tour?",
                "answer" : "Yes, absolutely! We offer customizable virtual tour experiences to meet your specific needs and preferences. Whether you want to highlight certain rooms, add informational overlays, or incorporate branding elements, we can tailor the virtual tour to align with your goals."
            },
            {
                "question" : "What types of properties can you create virtual tours for?", 
                "answer" : "We can create virtual tours for a wide range of properties, including residential homes, commercial spaces, hospitality venues, event venues, and more. Our virtual tours are customizable to suit the unique features and requirements of each property type."
            },
        ]
    },
    {
        'faq_group' : 'Most frequently asked questions about Animations:',
        'question_list' : [
            {
                "question" : "What are Animations?",
                "answer" : "Animations are dynamic visual presentations that bring static images or designs to life through movement, transitions, and effects. They are commonly used to showcase architectural designs, product concepts, or marketing campaigns in an engaging and compelling manner."
            },
            {
                "question" : "How can animations benefit my project?",
                "answer" : "Animations are powerful communication tools that can help convey complex ideas, evoke emotions, and captivate audiences. Whether you're presenting a new building design, demonstrating product functionality, or promoting a real estate development, animations can enhance understanding and engagement."
            },
            {
                "question" : "What types of animations do you offer?",
                "answer" : "We offer a variety of animation services, including architectural walkthroughs, product demonstrations, promotional videos, and more. Whether you need a simple animation to showcase a single room or a complex animation to illustrate a large-scale project, we have the expertise to bring your vision to life."
            },
            {
                "question" : "What types of animations can you create?",
                "answer" : "We can create a wide range of animations, including architectural walkthroughs, product demonstrations, promotional videos and more. Our animations are customized to suit your specific needs and objectives, whether you're showcasing a property, product, or concept."
            },
            {
                "question" : "Can you provide animations in different styles, such as 2D or 3D animation?",
                "answer" : "Yes, we can provide animations in different styles to suit your preferences and project requirements. Whether you prefer 2D animation for a clean and minimalist look or 3D animation for added depth and realism, we have the expertise to bring your vision to life."
            }
        ]
    },
    {
        'faq_group' : 'Most frequently asked questions about 360 Virtual Staging:',
        'question_list' : [
            {
                "question" : "What is 360 Virtual Staging?",
                "answer" : "360 virtual staging is an immersive marketing solution that allows viewers to explore fully furnished spaces from every angle. It involves digitally adding furniture, decor, and other elements to panoramic images, creating an interactive and realistic viewing experience."
            },
            {
                "question" : "How does 360 virtual staging differ from traditional staging?",
                "answer" : "Traditional staging involves physically furnishing a property with real furniture and decor, which can be costly and time-consuming. 360 virtual staging, on the other hand, is done digitally, allowing for quick and affordable transformations of empty spaces into fully furnished environments."
            },
            {
                "question" : "Can 360 virtual staging be applied to all types of properties?",
                "answer" : "Yes, 360 virtual staging can be applied to a wide range of properties, including residential homes, apartments, condos, commercial spaces, and more. Whether it's a vacant house, a retail storefront, or an event venue, 360 virtual staging can enhance the visual appeal and marketability of any property."
            },
            {
                "question" : "How does 360 virtual staging enhance the viewing experience for potential buyers or tenants?",
                "answer": "360 virtual staging provides a more immersive and interactive viewing experience compared to traditional static images. Viewers can explore fully furnished spaces from every angle, giving them a comprehensive understanding of the property's layout, design, and potential."
            },
            {
                "question" : "Can you provide 360 virtual staging for outdoor spaces as well as indoor spaces?",
                "answer" : "Yes, we can provide 360 virtual staging for both indoor and outdoor spaces to showcase the full potential of the property. Whether you want to highlight a landscaped garden, a rooftop terrace, or an outdoor entertainment area, we can digitally enhance the space to create a compelling presentation."
            },
            {
                "question" : "Are there limitations to 360 virtual staging, such as the types of properties or spaces it can be applied to?",
                "answer" : "While 360 virtual staging is incredibly versatile and realistic, it's important to note that it digitally enhances photographs rather than representing physical reality. As such, certain limitations may apply, such as properties with unusual layouts or highly unique architectural features. However, we strive to accommodate a wide range of properties and spaces to meet your needs."
            }
        ]
    },
    {
        'faq_group' : 'Some other frequently asked questions:',
        'question_list' : [
            {
                "question" : "Can I see examples of your previous work?",
                "answer" : "Yes, absolutely! We have a portfolio showcasing a diverse range of projects we've completed for our clients. These examples demonstrate the quality, creativity, and effectiveness of our services and provide valuable insights into the potential of our solutions for your property marketing needs."
            },
            {
                "question" : "How does the process work from start to finish?",
                "answer" : "The process typically begins with an initial consultation to discuss your project requirements, goals, and preferences. Once we have a clear understanding of your needs, we proceed with the project, keeping you informed and involved every step of the way. We welcome feedback and revisions to ensure the final result meets your expectations and objectives."
            },
            {
                "question" : "Can I request revisions or modifications to the work you provide?",
                "answer" : "Yes, absolutely! We welcome client feedback and revisions to ensure that the final result meets your expectations and objectives. Our goal is to deliver results that exceed your vision and leave you completely satisfied with the outcome. We offer unlimited revisions within a reasonable scope to ensure your satisfaction."
            },
            {
                "question" : "How do you ensure the confidentiality and security of my project information?",
                "answer": "We take confidentiality and security seriously and have strict measures in place to protect your project information. Our team adheres to confidentiality agreements, and we utilize secure communication channels and data encryption protocols to safeguard your sensitive information throughout the project lifecycle."
            },
            {
                "question" : "What is your pricing structure for your services?",
                "answer" : "Our pricing structure varies depending on the scope, complexity, and specific requirements of each project. We offer competitive pricing tailored to suit your budget and needs, and we provide transparent quotes upfront to ensure clarity and avoid any surprises. We strive to offer excellent value for money without compromising on quality or service."
            },
        ]
    },
]
