// Dependencies
import React from 'react';

// Styles
import '../../App.css';

// Components

// Data
import ErrorPage from '../error_page/ErrorPage';

// Sections



function ProjectItemPage() {
    return (
        <>
            <ErrorPage />
        </>
    );
}

export default ProjectItemPage;