// Dependencies
import React from 'react';

// Styles
import '../../App.css';

// Components
import Title from './sections/Title';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';
import FloatingButton from '../../components/global/FloatingButton';

// Data

// Sections

function TeamPage() {
    return (
        <>
            <Navbar />
            <Title />
            <Footer />
            <FloatingButton path='/contact' />
        </>
    );
}

export default TeamPage;