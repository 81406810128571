// Dependencies
import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import './Footer.css'

// Components

// Data

import { footer_data } from './data/footer_data'

function Footer() {
  return (
    <div className='footer-container'>
        <div className='footer-links'>
            <div className='footer-link-wrapper'>
            {
                footer_data.map(
                    (linkSection, index_1) => {
                        return <div data-aos="fade-up" data-aos-delay={index_1*300} key={index_1} className='footer-link-items hover-underline-link-animation'>
                            <h2 data-aos="fade-up" >{linkSection.title}</h2>
                            {
                                linkSection.data.map(
                                    (link, index_2) => {
                                        return <Link key={index_1 + '-' + index_2} to={"/"+link.path}>{link.text}</Link>
                                    }
                                )
                            }
                        </div>
                    }
                )
            }
            </div>
        </div>
        <section className='social-media'>
            <div className='social-media-wrap'>
                <div className='footer-logo'>
                    <Link to='/' className='social-logo'>
                        <img src={require('../../assets/images/logo/Artisana Visuals Logo.png')} className='footer-logo' alt={'ArtisanaVisuals'} />
                    </Link>
                </div>
                <small className='website-rights'>ArtisanaVisuals © 2024</small>
                <div className='social-icons'>
                    <Link to='https://www.behance.net/artisanavisuals' className='social-icon-link behance border-animation' target='_blank' aria-label='Behance'>
                        <i className='fab fa-behance'></i>
                    </Link>
                    <Link to='https://www.instagram.com/artisana_visuals/' className='social-icon-link instagram ' target='_blank' aria-label='Instagram'>
                        <i className='fab fa-instagram'></i>
                    </Link>
                    <Link to='https://www.linkedin.com/in/artisana-visuals-66271a2ba/' className='social-icon-link linkedin ' target='_blank' aria-label='LinkedIn'>
                        <i className='fab fa-linkedin'></i>
                    </Link>
                </div>
            </div>
        </section> 
    </div>
  )
}

export default Footer
