// Dependencies
import React from 'react';

// Styles
import '../../App.css';

// Components
import PageTitle from '../../components/global/PageTitle';
import ContactForm from './sections/ContactForm';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';

// Sections

function ContactPage() {
    return (
        <>
            <Navbar />
            <PageTitle text='Contact Us' path='Contact' />
            <ContactForm />
            <Footer />
        </>
    );
}

export default ContactPage;