import React, { useState } from 'react';

// Styles
import '../../App.css'
import './FAQuestion.css';

export const FAQuestion = ({
    question,
    answer
}) => {
    const [isClicked, setClick] = useState(false);

    const questionClick = () => {
        setClick(!isClicked);
    }

    return (
        <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="500" className='faq-question' onClick={questionClick}>
            <div className='faq-question-main'>
                <i className={`fa-solid fa-play ${isClicked && 'rotate-question-button'}`}></i>
                <div className='faq-question-text'>{question}</div>
            </div>
            <div className={`faq-question-answer expandable ${isClicked ? 'expandable-clicked' : ''}`}>{answer}</div>
            <span className='faq-question-separator' />
        </div>
    )
};
