// Dependencies
import React from 'react';

// Styles
import 'react-slideshow-image/dist/styles.css';
import '../../../App.css';
import './Main.css';

// Components
import { Slideshow } from '../../../components/images/Slideshow';

// Data 
import { welcome_text } from '../data/about_data';
import { slideshow_data_about } from '../../../data/slideshow_data';

function Main() {

  return (
    <div className='about-content-container'>
            <div className='about-images-container'>
              <Slideshow slideshowData={slideshow_data_about} delay={3500} /> 
            </div>
            <div className='about-text-container'>
              {
                welcome_text.map(
                  (text_group, index) => {
                    return <div key={'container_'+index} data-aos="fade-left" className='about-text-area'>
                      <h1 key={'head_'+index} >{text_group.title}</h1>
                      <p key={'para_'+index}>{text_group.text}</p>
                    </div>
                  }
                )
              }
            </div>
        </div>
  );
}

export default Main;
