// Dependencies
import React from 'react';

// Styles
import '../../../App.css';
import './Why.css';

// Components

// Data 
import { why_choose_text } from '../data/about_data';

function Why() {
  return (
    <div className='about-why-container'>
        <div data-aos="zoom-in" className='about-why-title-container'>
            <h1>Reasons to choose us</h1>
        </div>
        <div className='about-why-content-container'>
            <ul className='about-why-text-list'>
                {
                    why_choose_text.map(
                        (textGroup, index) => {
                            return  <li key={"item_"+index} data-aos="fade-up" data-aos-delay={index*300} className='about-why-text-item'>
                                <h2 key={"head1_"+index} >Reason {index+1}</h2>
                                <h3 key={"head2_"+index} >{textGroup.title}</h3>
                                <p key={"parag_"+index}> {textGroup.text}</p>
                            </li>     
                        }
                    )
                }
            </ul>
        </div>
    </div>
  );
}

export default Why;
