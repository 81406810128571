export const slideshow_data_main = [
    {
        "image" : require("../assets/images/pages/home_page/home_page_1.jpg"),
        "placeholder" : require("../assets/images/pages/home_page/home_page_1_placeholder.jpg"),
    },
    {
        "image" : require("../assets/images/pages/home_page/home_page_2.jpg"),
        "placeholder" : require("../assets/images/pages/home_page/home_page_2_placeholder.jpg"),
    },
    {
        "image" : require("../assets/images/pages/home_page/home_page_3.jpg"),
        "placeholder" : require("../assets/images/pages/home_page/home_page_3_placeholder.jpg"),
    }
]

export const slideshow_data_about = [
    {
        "image" : require('../assets/images/pages/about_page/about_page_1.jpg'),
        "placeholder" : require('../assets/images/pages/about_page/about_page_1_placeholder.jpg')
    },
    {
        "image" : require('../assets/images/pages/about_page/about_page_2.jpg'),
        "placeholder" : require('../assets/images/pages/about_page/about_page_2_placeholder.jpg')
    },
    {
        "image" : require('../assets/images/pages/about_page/about_page_3.jpg'),
        "placeholder" : require('../assets/images/pages/about_page/about_page_3_placeholder.jpg')
    },
    {
        "image" : require('../assets/images/pages/about_page/about_page_4.jpg'),
        "placeholder" : require('../assets/images/pages/about_page/about_page_4_placeholder.jpg')
    }
]