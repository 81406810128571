// Dependencies
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

// Styles
import './Navbar.css'

// Components
import { DefaultButton } from '../buttons/DefaultButton';
import { navbar_buttons_data } from './data/navbar_data';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const openMobileMenu = () => {
    setClick(true);
    document.body.style.overflow = "hidden";
  }
  const closeMobileMenu = () => {
    setClick(false);
    document.body.style.overflow = "auto";
  }

  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false)
    }
    else {
      setButton(true)
    }
  };

  useEffect(() => {
    showButton()
  }, [])

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
          <div className='navbar-container'>
            <Link to="/" className="navbar-logo-container" onClick={closeMobileMenu}>
              {/* ArtisanaVisuals <i className='fab fa-typo3'/> */}
              <img src={require('../../assets/images/logo/Artisana Visuals Logo.png')} className='navbar-logo' alt={'ArtisanaVisuals'} />
            </Link>
            <div className='menu-icon' onClick={click ? closeMobileMenu : openMobileMenu}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              {
                navbar_buttons_data.map(
                  (buttons_data, index) => {
                    return <li key={index} className={buttons_data.type==='nav-links' ? 'nav-item' : ''}>
                      <Link to={buttons_data.path} className={buttons_data.type} onClick={closeMobileMenu}>
                        {buttons_data.title}
                      </Link>
                    </li>
                  }
                )
              }
            </ul>
            {button && <DefaultButton animate={false} buttonStyle='btn--outline' linkTo='/contact'>GET IN TOUCH</DefaultButton>}
          </div>
      </nav> 
    </>
  );
}

export default Navbar