// Dependencies
import React from 'react';
import { useParams } from 'react-router-dom'

// Styles
import '../../App.css';

// Components
import PageTitle from '../../components/global/PageTitle';

// Data
import { services_data } from '../../data/services_data';
import ErrorPage from '../error_page/ErrorPage';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';
import FloatingButton from '../../components/global/FloatingButton';

// Sections

function ServiceItemPage() {
    const {serviceID} = useParams();

    if(Object.keys(services_data).includes(serviceID)) {
        const serviceData = services_data[serviceID];

        return (
            <>
                <Navbar />
                <PageTitle text={serviceData.name} group={{"path" : "services-list", "name" : "Services"}} path={serviceData.name} />
                <iframe title='tour_service' width="100%" height="640" frameBorder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7cJXT?logo=1&info=1&fs=1&vr=0&sd=1&autorotate=0.16&thumbs=1"></iframe>
                <Footer />
                <FloatingButton path='/contact' />
            </>
        );
    }
    else {
        return (
            <>
                <ErrorPage />
            </>
        );
    }
}

export default ServiceItemPage;