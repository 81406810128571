// Dependencies
import React from 'react';

// Styles
import './ProjectSelectionButton.css';

export const ProjectSelectionButton = ({
    selectionCondition,
    onClick,
    children
}) => {
    return (
        <button className={`project-selection-button ${selectionCondition ? 'project-button-selected' : ''}`} onClick={onClick}>
            {children}
        </button>
    )
};
