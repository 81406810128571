// Dependencies
import React from 'react'
import { Link } from 'react-router-dom';

// Styles 
import '../../App.css'
import './FloatingButton.css';

function FloatingButton(props) {

  return (
    <div className='floating-button-container'>
        <Link to={props.path} target='_blank'>
            <i className='fa-regular fa-message'></i>
        </Link>
    </div>
  )
}

export default FloatingButton
