// Dependencies
import {React, useState} from 'react';

// Styles
import '../../../App.css';
import './Radio.css';

// Components
import { CircularRadioButton } from '../../../components/buttons/CircularRadioButton';

// Data
import { radio_buttons_data } from '../data/radio_buttons_data';

const radioButtonKeys = Object.keys(radio_buttons_data)

function Radio() {
  const [selection, setSelection] = useState(radioButtonKeys[0]);

  const clickHandle = (id) => {
    setSelection(id)
  } 

  return (
    <div className='radio-container'>
        <div className='radio-container-background' />
        <div className='radio-content-container'>
          <h1 data-aos="fade-up" >Our Steps to Greatness</h1>
          <div data-aos="zoom-in" className='radio-separator-container'>
            <span className='radio-separator' /> <i className='fa fa-circle white' /> <span className='radio-separator' />
          </div>
          <div className='buttons-and-text'>
            <div className='radio-info-buttons-container'>
              {
                radioButtonKeys.map(
                  (key, index) => {
                    return <CircularRadioButton key={index} selectionCondition={key === selection} onClick={() => clickHandle(key)} delay={index*300}>
                      { radio_buttons_data[key].name }
                    </CircularRadioButton>
                  }
                )
              }
            </div>
            <span data-aos="zoom-in" className='radio-separator bottom' />
            <p data-aos="fade-up">{ radio_buttons_data[selection].text }</p>
          </div>
        </div>
    </div>
  );
}

export default Radio;
