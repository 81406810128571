// Dependencies
import React from 'react';

// Styles
import '../../App.css';

// Components
import Main from './sections/Main';
import Why from './sections/Why';
import PageTitle from '../../components/global/PageTitle';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';
import FloatingButton from '../../components/global/FloatingButton';

// Sections

function AboutPage() {
    return (
        <>
            <Navbar />
            <PageTitle text='About Us' path='About' />
            <Main />
            <Why />
            <Footer /> 
            <FloatingButton path='/contact' />
        </>
    );
}

export default AboutPage;