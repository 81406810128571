// Dependencies
import React from 'react';

// Styles
import '../../App.css';

// Components
import Content from './sections/Content';
import Navbar from '../../components/global/Navbar';
import Footer from '../../components/global/Footer';
import FloatingButton from '../../components/global/FloatingButton';

// Sections

function ErrorPage() {
    return (
        <>
            <Navbar />
            <Content />
            <Footer />
            <FloatingButton path='/contact' />
        </>
    );
}

export default ErrorPage;