// Dependencies
import React from 'react';

// Styles
import './ProjectsImages.css';
import BlurryLoadingImage from './BlurryLoadingImage';

function getComponentType(project) {
    switch (project.type) {
        case 'normal-image':
            return <BlurryLoadingImage image={project.image} preview={project.image_placeholder} />;
        case 'comparison-image':
            return <BlurryLoadingImage image={project.after_image} preview={project.after_placeholder} />;
        case 'tour':
            return <BlurryLoadingImage image={project.image} preview={project.placeholder} />;
        case 'video':
            return <BlurryLoadingImage image={project.image} preview={project.placeholder} />;
        default: 
            return
    }
}

export const ProjectsImages = ({
    project,
    click
}) => {
    const componentType = getComponentType(project);

    return (
        <div className='project-img-component-container project-img-zoom-in' onClick={click}>
            <figure className='project-figure-container'>
                    { componentType }
                    <div className='project-img-overlay'>
                    </div>
            </figure>
        </div>
    )
};
