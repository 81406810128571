// Dependencies
import React from 'react';
import {Link} from 'react-router-dom';

// Styles
import './DefaultButton.css';

const STYLES = [
    'btn--primary',
    'btn--outline',
    'btn--outline--bigger'
];

const SIZES = [
    'btn--medium',
    'btn--large'
];

export const DefaultButton = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    linkTo,
    animate
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle: STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <Link data-aos={animate ? "fade-up" : ""} to={linkTo} className='btn-mobile'>
            <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>
                {children}
            </button>
        </Link>
    )
};
