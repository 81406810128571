import React, { useEffect, useState } from 'react';

import './BlurryLoadingImage.css'

const BlurryLoadingImage = ({
  preview,
  image,
  alt,
  imageStyleClass,
  divStyleClass,
  slideshow,
  opacity,
  bgColor = 'transparent',
}) => {
  const [currentImage, setCurrentImage] = useState(preview);
  const [loading, setLoading] = useState(true);

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(image);
  }, [image]);

  return (
    <div className={divStyleClass} id={slideshow} style={{ 
        overflow: 'hidden', 
        width: '100%', 
        height: '100%', 
        opacity: `${opacity}`,
        display: 'flex'
        }}>
      <img 
        loading='lazy'
        style={{
          filter: `${loading ? 'blur(20px)' : ''}`,
          transition: '1s filter linear, 0.5s transform ease-out',
        }}
        src={currentImage}
        alt={alt}
        className={imageStyleClass !== undefined ? imageStyleClass : 'img__default'}
      />
    </div>
  );
};

export default BlurryLoadingImage;